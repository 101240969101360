import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  EditButton,
  DateField,
  SelectInput,
  SelectField
} from 'react-admin';



const searchFilters = [
  <TextInput label="Search Post Id" source="postId" alwaysOn />,
  <TextInput label="Search Idol Id" source="idolId" alwaysOn />,
];

const statusOptions = [
  { value: 'processing', text: 'Processing' },
  { value: 'processed', text: 'Processed' }
]

export const PostReportList = (props) => {
  
  return (
    <List {...props} filters={searchFilters} title="Post Reports">
      <Datagrid rowClick="show">
        <TextField source="reportId" label="Report Id" />
        <TextField source="postId" label="Post Id" />
        <TextField source="category" />
        <TextField source="idolId" label="Idol Id" />
        <TextField source="userId" label="User Id" />
        <TextField source="yearMonthDay" label="Report Date" />
        <SelectField source="status" choices={statusOptions} optionValue="value" optionText="text" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const PostReportShow = (props) => {
  return (
    <Show {...props} title="Post Report view" >
      <SimpleShowLayout>
        <TextField source="reportId" label="Report Id" />
        <TextField source="postId" label="Post Id" />
        <TextField source="category" />
        <TextField source="idolId" label="Idol Id" />
        <TextField source="userId" label="User Id" />
        <DateField source="yearMonthDay" label="Report Date" />
        <SelectField source="status" choices={statusOptions} optionValue="value" optionText="text" />
      </SimpleShowLayout>
    </Show>
  )
}

export const PostReportEdit = (props) => (
  <Edit {...props} title="Post Report Edit" >
    <SimpleForm>
      <TextInput fullWidth disabled source="reportId" label="Report Id" />
      <TextInput fullWidth disabled source="postId" label="Post Id" />
      <TextInput source="category" />
      <TextInput fullWidth disabled source="idolId" label="Idol Id" />
      <TextInput fullWidth disabled source="userId" label="User Id" />
      <DateInput fullWidth source="yearMonthDay" label="Report Date" />
      <SelectInput fullWidth source="status" choices={statusOptions} optionValue="value" optionText="text" />
    </SimpleForm>
  </Edit>
);