import env from 'src/env';

const authProvider = {
  login: async ({ username, password }) => {

  const url = `https://${env.apiDomain}/admin/auth/log-in`;
        console.log("url",url)
    const res = await fetch(
      url,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      }
    );

    const result = await res.json();

    console.log('result: ', result);

    if (result.success) {
      const data = result.data;
      localStorage.setItem('adminUserId', data.userId);
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('permissions', 'admin');
    } else {
      alert(result.code);
    }
  },

  checkAuth: () => localStorage.getItem('adminUserId')
    ? Promise.resolve()
    : Promise.reject({ message: 'login.required' }),

  logout: async () => {
    const loginId = localStorage.getItem('adminUserId');
    const token = localStorage.getItem('token');
    await fetch(`https://${env.apiDomain}/auth/logout`, {
      method: 'POST',
      headers: {
        Authorization: `${loginId}_${token}`,
      },
    });
    localStorage.removeItem('adminUserId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    console.log("role",role)
    return role ? Promise.resolve(role) : Promise.reject();
  }
};

export default authProvider;