import './App.css';
import { Admin, Resource,defaultTheme } from 'react-admin';
import * as React from "react";
import { Route } from "react-router-dom";
import dataProvider from '../src/dataProvider';
import authProvider from '../src/authProvider';
import { PostList, PostShow, PostEdit } from '../src/resources/posts';
import { CreatorShow, CreatorEdit, CreatorsCustomlist, AddNewCreator } from '../src/resources/creators';
import { PaymentList, PaymentShow, PaymentEdit, PaymentCreate } from '../src/resources/payments';
import { PostReportList, PostReportShow, PostReportEdit } from '../src/resources/postReports';
import { UserReportList, UserReportShow, UserReportEdit } from '../src/resources/userReports';
import { SubscriptionList, SubscriptionShow, SubscriptionEdit } from '../src/resources/subscriptions';
import { TierList, TierShow, TierEdit } from '../src/resources/tiers';
import { CreatorOnboardingApplicationList, CreatorOnboardingApplicationShow, CreatorOnboardingApplicationEdit } from '../src/resources/creatorOnboardingApplications';
import { FanShow, FanEdit, FansCustomlist } from '../src/resources/fans';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import { ChangePassword } from './resources/changePassword';
import { NewPost } from './resources/newPost';


//Here Route define for all pages



function App() {


  return (
    <Admin
   
  //  loginPage={MyLoginPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
     
      customRoutes={[
        <Route
          exact
          path="/ChangePassword/:userId"
          component={ChangePassword}
        />,
        <Route
          exact
          path="/new-post/:userId"
          component={NewPost}
        />
      ]} >

      <Resource name="creators" icon={AccountBoxOutlinedIcon} list={CreatorsCustomlist} show={CreatorShow} edit={CreatorEdit}
        create={AddNewCreator}

      />
      <Resource name="posts" icon={DescriptionOutlinedIcon} list={PostList} show={PostShow} edit={PostEdit} />

      <Resource name="fans" icon={PeopleAltOutlinedIcon} list={FansCustomlist} show={FanShow} edit={FanEdit} />
      <Resource name="tiers" icon={BadgeOutlinedIcon} list={TierList} show={TierShow} edit={TierEdit} />
      <Resource name="subscriptions" icon={ListAltOutlinedIcon} list={SubscriptionList} show={SubscriptionShow} edit={SubscriptionEdit} />
      <Resource name="creatorOnboardingApplications"
        icon={ContactsOutlinedIcon}
        list={CreatorOnboardingApplicationList}
        show={CreatorOnboardingApplicationShow}
        edit={CreatorOnboardingApplicationEdit}
        options={{ label: 'Creator Onboarding' }}
      />
      <Resource name="payments" list={PaymentList} show={PaymentShow} edit={PaymentEdit} create={PaymentCreate} />

      <Resource
        name="postReports"
        icon={() => <ContactsOutlinedIcon style={{ color: '#fff', height: 0, width: 0 }} />}
        options={{ label: ' ' }} list={PostReportList} show={PostReportShow} edit={PostReportEdit} />

      <Resource
        name="userReports"
        
        icon={() => <ContactsOutlinedIcon style={{ color: '#fff', height: 0, width: 0 }} />}
       options={{ label: ' ', }} 
       list={UserReportList}
        show={UserReportShow}
         edit={UserReportEdit} />

    </Admin>



  );
}

export default App;
