import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Show,
  SimpleShowLayout,
  useGetOne,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateTimeInput,
  EditButton,
  Labeled,
  SelectInput,
  SelectField,
  DateField,
  DateInput,
  TopToolbar,
  ExportButton,
  Button,
  ImageField,
  ImageInput,
  FileInput,
  useUpdateMany,
  useUpdate,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
  useDataProvider,
  useMutation,


} from 'react-admin';

import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom"
import moment from "moment";
import DateFieldForTimestamp from 'src/components/DateFieldForTimestamp';
import dataProvider from 'src/dataProvider';
import { PostReportList } from './postReports';
import { isEmpty } from 'src/components/customfunction';
import UniversalFeed from './UniversalFeed';
import CancelIcon from "@mui/icons-material/Cancel";

const formatDateTime = (v) => {
  if (typeof v === 'number') {
    const string = moment.unix(v).format('YYYY-MM-DDTHH:mm');
    return string;
  } else if (typeof v === 'string') {
    return v
  }
}

const parseDateTime = (v) => {
  const number = moment(v, 'YYYY-MM-DDTHH:mm').unix();
  return number
}

const searchFilters = [
  <TextInput label="Search User Id" source="userId" alwaysOn />,
  <TextInput label="Search Post Id" source="postId" alwaysOn />
];

const accessOptions = [
  { value: 1, text: 'Public' },
  { value: 2, text: 'Private' }
]

const postTypeOptions = [
  { value: 'image', text: 'Image' },
  { value: 'video', text: 'Video' },
  // { value: null, text: 'Text' }
]

const publishStatusOptions = [
  { value: 1, text: 'Draft' },
  { value: 2, text: 'Published' }
]


const CustomPostList = (props) => {
  const [userdata, setUserdata] = useState()
  const onRowSelect = (record) => {
    setUserdata(record)
  }
  const ListActions = (props) => {
    const history = useHistory()
    return (
      <TopToolbar>
        {!isEmpty(userdata?.userId) && <Button label='NEW POST' onClick={() => history.push(`/new-post/${userdata?.userId}`)} />}
        <ExportButton />
      </TopToolbar>
    )
  }

  return (
    <List {...props} filters={searchFilters}
      actions={<ListActions {...props} />}>
      <Datagrid rowClick={(id, resource, record) => onRowSelect(record)}>
        {/* <TextField source="username" label="User Name" /> */}
        <TextField source="userId" label="User Id" />
        <TextField source="postId" label="Post Id" />
        <TextField source="title" />
        <TextField source="description" style={{ width: '50px' }} />
        <SelectField source="postType" choices={postTypeOptions}
         optionText="text"
          optionValue="value" />
        <DateFieldForTimestamp source="publishDate" />
        <EditButton />
      </Datagrid>
    </List>
  )
}



export const PostList = (props) => {
  const history = useHistory()
  const [state, setState] = useState(0)
  let menulist = [
    { name: 'Post Records', status: true, key: "postrecord" },
    { name: 'Post Reports', status: false, key: "postreport" },
    { name: 'Universal Feed', status: false, key: "universalfeed" },
  ]
  const [menuState, setMenuState] = useState(menulist)
  useEffect(() => {

  }, [state, menuState])
  const onchange = (key) => {
    setState(key)
    menuState.forEach(element => {
      element["status"] = false
    });
    menuState[key].status = true
    if (key === 1) {
      dataProvider.getResourcename('getlist')
    }
  }

  return (
    <>
      <div class="top-header-tab">
        {menuState?.map((item, index) =>
          <div label={item.key} className={item.status ? "top-header-tab-title active" : "top-header-tab-title"} onClick={() => onchange(index)}>
            {item.name}
          </div>

        )}

      </div>

      {state === 0 && <CustomPostList {...props} />}
      {state === 1 &&
        <CustomReportshow {...props} />

      }
      {state === 2 && <UniversalFeed />}
    </>
  )
}

export const PostShow = (props) => {
 
  const { data } = useGetOne('posts', props.id);
  return (
    <Show title="Post view" {...props} >
      <SimpleShowLayout>
        <TextField source="userId" label="User Id" />
        <TextField source="postId" label="Post Id" />
        <TextField source="title" />
        <TextField source="description" />
        <SelectField source="postType" choices={postTypeOptions} optionText="text" optionValue="value" />
        <SelectField source="access" choices={accessOptions} optionText="text" optionValue="value" />
        <TextField source="selectTier" />
        <NumberField source="likeCount"  />
        <NumberField source="commentCount" />
        <SelectField source="publishStatus" choices={publishStatusOptions} optionText="text" optionValue="value" />
        <Labeled label="Publish Date">
          <DateFieldForTimestamp source="publishDate" />
        </Labeled>
        <TextField source="media" />
        {
          data && data.postType === 'image' ?
            <div>
              {
                data?.media?.map((image, index) => {
                  return (
                    <img
                      src={image}
                      style={{ maxWidth: 300, maxHeight: 300 }}
                      alt="post"
                      key={`picture_${index}`}
                    />
                  )
                })
              }
            </div>
            :
            data && data.postType === 'video' ?
              <div>
                {
                  data.media.map((video, index) => {
                    return (
                      <video
                        width="320" height="240" controls
                        key={`video_${index}`}
                        muted
                      >
                        <source src={video} />
                      </video>
                    )
                  })
                }

              </div> :
              null
        }
      </SimpleShowLayout>
    </Show>
  )
}


var postType;

let imagerecord

function PreviewImage({ record, source }) {
 
  if (typeof record == "string") {
    record = {
      [source]: record
    };
  }
  imagerecord = record;

  return (
    postType === "video" ? (
      <video src={record.src} style={{ marginRight: 19 }} />
    ) : (

      <ImageField source={source} record={record} />
    )
  );
}

export const PostEdit =(props) => {
  const { data } = useGetOne('posts', props.id);
  const [posttypevalue,setPosttypevalue]=useState(isEmpty(data?.postType)?postTypeOptions[0].value:data?.postType)
  const [disableflag,setDisableflag]=useState(isEmpty(data?.postType)?false:true)
 postType=posttypevalue


useEffect(()=>{
 if(data?.media?.length>0){
    setDisableflag(true)
  }
  },[disableflag])
console.log("w8or2",data?.postType,data?.media)
return(
<Edit title="Post Edit" {...props} >
    <SimpleForm>
      <TextInput fullWidth disabled source="userId" label="User Id" />
      <TextInput fullWidth disabled source="postId" label="Post Id" />
      <TextInput fullWidth source="title" />
      <TextInput fullWidth multiline source="description" />
      <SelectInput fullWidth source="postType"
        defaultValue={postTypeOptions[0].value}
        disabled={disableflag}
        value={postType}
        
        onChange={(e) => setPosttypevalue(e.target.value)}
      choices={postTypeOptions} optionText="text" optionValue="value" />
      <SelectInput fullWidth source="access" choices={accessOptions} optionText="text" optionValue="value" />
      <TextInput fullWidth source="selectTier" />
      <NumberInput fullWidth source="likeCount" disabled />
      <NumberInput fullWidth source="commentCount" disabled />
      <SelectInput fullWidth source="publishStatus" choices={publishStatusOptions} optionText="text" optionValue="value" />
      <DateTimeInput
        fullWidth
        source="publishDate"
        format={formatDateTime}
        parse={parseDateTime}/>
       
           <FileInput source="media" label="media" 
           multiple={true}
           accept={`${posttypevalue}/*`}
           placeholder={<p>Upload Post</p>}>
           <PreviewImage source="src" />
              </FileInput> 
              {/* </div> */}

    </SimpleForm>
  </Edit>
)};



const searchFilters1 = [
  <TextInput label="Search Post Id" source="postId" alwaysOn />,
  <TextInput label="Search Idol Id" source="idolId" alwaysOn />,
];

const statusOptions = [
  { value: 'processing', text: 'Processing' },
  { value: 'processed', text: 'Processed' }
]
function removeFromArray(original, remove) {
  return original.filter(value => !remove.includes(value));
}

export const CustomReportshow = (props) => {

  let customprops = { ...props }
  customprops.basePath = "postReports"
  customprops.resource = "postReports"
  return (
    <PostReportList {...customprops} />
  )
}


export const PostReportShow = (props) => {

  const customprops = { ...props }
  customprops.resource = "postReports"
  return (
    <Show {...customprops} title="Post Report view" >
      <SimpleShowLayout>
        <TextField source="reportId" label="Report Id" />
        <TextField source="postId" label="Post Id" />
        <TextField source="category" />
        <TextField source="idolId" label="Idol Id" />
        <TextField source="userId" label="User Id" />
        <DateField source="yearMonthDay" label="Report Date" />
        <SelectField source="status" choices={statusOptions} optionValue="value" optionText="text" />
      </SimpleShowLayout>
    </Show>
  )
}

export const PostReportEdit = (props) => {

  const customprops = { ...props }
  customprops.resource = "postReports"
  return (
    <Edit {...customprops} title="Post Report Edit" >
      <SimpleForm>
        <TextInput fullWidth disabled source="reportId" label="Report Id" />
        <TextInput fullWidth disabled source="postId" label="Post Id" />
        <TextInput source="category" />
        <TextInput fullWidth disabled source="idolId" label="Idol Id" />
        <TextInput fullWidth disabled source="userId" label="User Id" />
        <DateInput fullWidth source="yearMonthDay" label="Report Date" />
        <SelectInput fullWidth source="status" choices={statusOptions} optionValue="value" optionText="text" />
      </SimpleForm>
    </Edit>
  )
};

