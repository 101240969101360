import {
  SimpleForm,
  TextInput,
  Button,
  ShowButton,

} from 'react-admin';
import '../App.css'
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import dataProvider from "src/dataProvider";
import { isEmpty } from 'src/components/customfunction';

function removeForbiddenCharacters(input) {
  let forbiddenChars = ['/', '?', '&', '=', '.', '"', '%20', '[', ']']

  for (let char of forbiddenChars) {
    input = input.split(char).join(' ');
  }
  return input
}


export const ChangePassword = (props) => {

  const history = useHistory();
  const [oldPassword, setOldPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmpassword, setConfirmpassword] = useState(null);
  let value = removeForbiddenCharacters(props.match.params.userId)
  // console.log("change password---------------",value)
  const onChangePassword = () => {

    if (!isEmpty(oldPassword) && !isEmpty(password) && !isEmpty(confirmpassword)) {
    
      if(password===confirmpassword){
        let params = {
          oldPassword: oldPassword,
          newPassword: password,
          
        }
        dataProvider?.changePassword(value.trim(), params).then(res => {
        
          if (res?.data?.success) {
            alert("Successfully Update Password")
          }else{
            if(res?.data?.code==='WRONG_OLD_PASSWORD')
            alert("Wrong Old Password")
          }
  
        })
      }
      else{
        alert("Please Enter Newpassword and Confirm password same")
      }
    
    }
    else {
      alert("Please Enter Your Field")
    }

  }

  const onCancel = () => {
    history.goBack("/", { replace: true });
  }
  return (
    <div>
      {/* <div style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%', display: 'flex' }}>
        <ShowButton label="SHOW" onClick={() => onChangePassword()} />
      </div> */}
      <SimpleForm className='formsimpleform'
        defaultValues={{
          oldPassword: '....',
          password: '....',
          confirmpassword: 123,
        }}
        toolbar={false}>
        <TextInput

          fullWidth
          source="oldPassword"
          type="password"
          label="Old Password"
          onChange={event => setOldPassword(event.target.value)} />

        <TextInput fullWidth
          source="password"
          type="password"
          label="New Password"
          onChange={event => setPassword(event.target.value)} />
        <TextInput fullWidth source="confirmpassword" type="password" label="Confirm New Password"
          onChange={event => setConfirmpassword(event.target.value)}
        />
        <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', display: 'flex' }}>
       {/* <Button label="UPDATE" 
           className="textcolor" />  */}
       <div onClick={() => onChangePassword()}  className="buttonupdate">
           {"UPDATE"}
           </div> 
          <Button label="CANCEL" onClick={() => onCancel()} className="textcolorcancel" />
        </div>
      </SimpleForm>
    </div>
  )
}