import { stringify } from "query-string";
import env from "../src/env";
import axios from "axios";
import _get from "lodash/get";
import _random from "lodash/random";
import _forEach from "lodash/forEach";
import _split from "lodash/split";
import convert from "xml-js";
import moment from "moment";

import { isEmpty } from "./components/customfunction";

const generateExclusiveStartKey = (resource, lastItem) => {
  switch (resource) {
    case "posts":
      return {
        userId: lastItem.userId,
        postId: lastItem.postId,
      };

    case "creators":
      return {
        userId: lastItem.userId,
        type: "idol",
      };

    case "fans":
      return {
        userId: lastItem.userId,
        type: "fan",
      };

    case "payments":
      return {
        referenceNumber: lastItem.referenceNumber,
      };

    case "postReports":
      return {
        postId: lastItem.postId,
        reportId: lastItem.reportId,
      };

    case "subscriptions":
      return {
        subscribeId: lastItem.subscribeId,
      };

    case "tiers":
      return {
        userId: lastItem.userId,
        tierId: lastItem.tierId,
      };

    case "creatorOnboardingApplications":
      return {
        userId: lastItem.userId,
      };

    case "userReports":
      return {
        accusedId: lastItem.accusedId,
        reportId: lastItem.reportId,
      };

    default:
      return {};
  }
};

const generateId = (resource, item) => {
  switch (resource) {
    case "posts":
      return [item.userId, item.postId];

    case "creators":
      return [item.userId];

    case "fans":
      return [item.userId];

    case "payments":
      return [item.referenceNumber];

    case "postReports":
      return [item.postId, item.reportId];

    case "subscriptions":
      return [item.subscribeId];

    case "tiers":
      return [item.userId, item.tierId];

    case "creatorOnboardingApplications":
      return [item.userId];

    case "userReports":
      return [item.accusedId, item.reportId];

    default:
      return [];
  }
};

let cache = [];
let globalResource;
let globalFilter = {};
let globalAction;
let hasMore;
let mediacollection = []


const dataProvider = {
  getResourcename: (key) => {
    let temparam = {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
      filter: {},
    };

    // if(resoucename=="postReports"){
    switch (key) {
      case "getlist":
        dataProvider.getList("postReports", temparam);
        break;
      case "getOne":
        dataProvider.getOne("postReports", temparam);
        break;

      default:
        break;
    }

    //  }
  },

  getList: async (resource, params) => {
 
    const { page, perPage } = params?.pagination;
    const { field, order } = params?.sort;
    const resourcename = resource;

    const filter = params?.filter;

    if (
      JSON.stringify(globalFilter) !== JSON.stringify(filter) ||
      resource !== globalResource ||
      globalAction !== "getList"
    ) {
      cache = [];
      globalResource = resource;
      globalFilter = filter;
      globalAction = "getList";
    }

   

    let lengthNeeded = page * perPage;
    let currentLength = cache?.length;
  
    if (lengthNeeded > currentLength || cache.length === 0) {
      let exclusiveStartKey;
      if (cache.length === 0) {
       
        exclusiveStartKey = null;
      } else {
        let lastItem = cache[cache.length - 1];
        exclusiveStartKey = JSON.stringify(
          generateExclusiveStartKey(resource, lastItem)
        );
      }



      const query = {
        limit: lengthNeeded - currentLength,
        // exclusiveStartKey,
        filter: JSON.stringify(filter),
      };

      const url = `https://${env.apiDomain
        }/admin/resource/${resourcename}?${stringify(query)}`;
        console.log("getList",url)
      const loginId = localStorage.getItem("adminUserId");
      const token = localStorage.getItem("token");
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `${loginId}_${token}`,
        },
      });
      const result = await res.json();
      console.log("list",result)
      let data = result?.data?.sort((a, b) => resource === 'creators' ? (b?.updatedAt - a?.updatedAt) : (b?.createdAt - a?.createdAt));

      data = data?.map((item) => {
        let newItem = {
          ...item,
          id: JSON.stringify(generateId(resource, item)),
        };
        return newItem;
      });
      // cache = cache.concat(data);
      cache = data;
      hasMore = !!result.lastEvaluatedKey;
    }

    let ourData = JSON.parse(JSON.stringify(cache));

    if (field !== "id") {
      if (order === "ASC") {
        ourData?.sort((a, b) => {
          if (a[field] == null) {
            return -1;
          } else if (b[field] == null) {
            return 1;
          } else if (a[field] > b[field]) {
            return 1;
          } else if (a[field] < b[field]) {
            return -1;
          } else {
            return 0;
          }
        });
      } else {
        ourData?.sort((a, b) => {
          if (a[field] == null) {
            return 1;
          } else if (b[field] == null) {
            return -1;
          } else if (a[field] > b[field]) {
            return -1;
          } else if (a[field] < b[field]) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    }

    const startIndex = (page - 1) * perPage;
    const endIndex = page * perPage;
    let pageData = ourData.slice(startIndex, endIndex);
    
    return {
      data: pageData,
      total: cache?.length + (hasMore ? 1 : 0),
    };
  },

  getOne: async (resource, params) => {

    globalAction = "getOne";

    let query = {
      id: params.id,
    };


    const url = `https://${env.apiDomain
      }/admin/resource/${resource}/one?${stringify(query)}`;
    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `${loginId}_${token}`,
      },
    });

    const result = await res.json();

   

    let data = result.data;
    data["id"] = JSON.stringify(generateId(resource, data));

    return {
      data: data,
    };
  },

  getMany: async (resource, params) => {
    globalAction = "getMany";

    let query = {
      ids: params.ids,
    };

    const url = `https://${env.apiDomain
      }/admin/resource/${resource}/many?${stringify(query)}`;

    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `${loginId}_${token}`,
      },
    });

    const result = await res.json();


    let data = result.data;
    data = data?.map((item) => {
      return {
        ...item,
        id: JSON.stringify(generateId(resource, item)),
      };
    });

    return {
      data: data,
    };
  },


  update: async (resource, params) => {
    globalAction = "update";
    let query = {
      id: params.id,
    };
    if (resource === 'posts') {
      let imagetemp =  params?.data?.media?.filter(res => res?.src?((res?.rawFile?.type).toString())?.includes(params?.data?.postType):res)
     
      for (let index = 0; index < imagetemp?.length; index++) {
        let tempimage = (imagetemp[index]?.src !== undefined) ? await dataProvider.uploadImage(imagetemp[index]) : imagetemp[index]
        mediacollection.push(tempimage)
      }
     
      let postupdate = {
        ...params?.data,
        media: mediacollection?.length > 0 &&  mediacollection.filter((v,i,a)=>a.findIndex(v2=>(v2===v))===i)
      }
      console.log("postupdate",postupdate)
    if(mediacollection?.length>0){

      const url = `https://${env.apiDomain
        }/admin/resource/${resource}/one?${stringify(query)}`;
      const loginId = localStorage.getItem("adminUserId");
      const token = localStorage.getItem("token");
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `${loginId}_${token}`,
        },

        body: JSON.stringify(postupdate)
      });

      const result = await res.json();

     

      let data = result?.data;

      return {
        data: data,
      };
    }
    }
    else {
      let avatarimage = isEmpty(await dataProvider.uploadImage(params?.data?.avatar)) ? params?.data?.avatar : await dataProvider.uploadImage(params?.data?.avatar);
      let profileImage = isEmpty(await dataProvider.uploadImage(params?.data?.profileImage)) ? params?.data?.profileImage : await dataProvider.uploadImage(params?.data?.profileImage)
      let identityProof = isEmpty(await dataProvider.uploadImage(params?.data?.identityProof)) ? params?.data?.identityProof : await dataProvider.uploadImage(params?.data?.identityProof)
      let imagetemp = isEmpty(await dataProvider.uploadImage(params?.data?.image)) ? params?.data?.image : await dataProvider.uploadImage(params?.data?.image)
      let creatorparams = {
        ...params.data,
        avatar: avatarimage,
        profileImage: profileImage,
      };
      let createonboardingapplication={
        ...params.data,
        profileImage: profileImage,
        identityProof:identityProof
      }
      let tiersparams={
        ...params.data,
        image:imagetemp
      }
      const url = `https://${env.apiDomain
        }/admin/resource/${resource}/one?${stringify(query)}`;
      const loginId = localStorage.getItem("adminUserId");
      const token = localStorage.getItem("token");
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `${loginId}_${token}`,
        },

        body: JSON.stringify(resource === 'creators' ? creatorparams 
        :resource==='creatorOnboardingApplications'? 
        createonboardingapplication 
        :
        resource==='tiers'? 
        tiersparams :
        params?.data)
      });

      const result = await res.json();


      let data = result?.data;

      return {
        data: data,
      };
    }

  },

  createAdmin: async (params) => {

    const url = `https://${env.apiDomain}/admin/create-new-creator`;
    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");
  
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `${loginId}_${token}`,
      },
      body: JSON.stringify(params),
    });

    const result = await res.json();
  
    if (result.errorType) {
      alert("Something went Wrong");
    } else {
      return {
        result
      };
    }
  },

  create: async (resource, params) => {
    globalAction = "create";

    if (resource === "addNewAdmin") {
      let avatarimage = isEmpty(await dataProvider.uploadImage(params?.data?.avatar)) ? params?.data?.avatar : await dataProvider.uploadImage(params?.data?.avatar);
      let profileImage = isEmpty(await dataProvider.uploadImage(params?.data?.profileImage)) ? params?.data?.profileImage : await dataProvider.uploadImage(params?.data?.profileImage)
      let identityProof = isEmpty(await dataProvider.uploadImage(params?.data?.identityProof)) ? params?.data?.identityProof : await dataProvider.uploadImage(params?.data?.identityProof)


      let decisionflag = 0

      let createadmindata = {
        ...params.data,
        avatar: avatarimage,
        profileImage: profileImage,
        identityProof: identityProof
      };
      decisionflag = !isEmpty(params?.data?.confirmpassword) === !isEmpty(params?.data?.password) ? 0 : 1
      // decisionflag = isEmpty(identityProof) ? 2 : 0

      switch (decisionflag) {
        case 1:
          alert("Please Enter Same Password")
          break;
        // case 2:
        //   alert("Please Select Your Identity Proof")
        //   break;
        case 0:
          dataProvider.addAdmin(createadmindata)

          break;
        default:
          break;
      }


    } else {
      const url = `https://${env.apiDomain}/admin/resource/${resource}`;
     
      const loginId = localStorage.getItem("adminUserId");
      const token = localStorage.getItem("token");
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `${loginId}_${token}`,
        },
        body: JSON.stringify(params?.data),
      });

      const result = await res.json();
    
      let data = result.data;
      data["id"] = JSON.stringify(generateId(resource, data));

      return {
        data: data,
      };
    }

  
  },

  delete: async (resource, params) => {
    globalAction = "delete";

    let query = {
      id: params.id,
    };

    const url = `https://${env.apiDomain
      }/admin/resource/${resource}/one?${stringify(query)}`;
    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `${loginId}_${token}`,
      },
    });

    const result = await res.json();


    let data = result?.data;

    cache = [];

    return {
      data: data,
    };
  },

  deleteMany: async (resource, params) => {
    globalAction = "deleteMany";

    let query = {
      ids: JSON.stringify(params.ids),
    };

   

    const url = `https://${env.apiDomain
      }/admin/resource/${resource}/many?${stringify(query)}`;
    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `${loginId}_${token}`,
      },
    });

    const result = await res.json();
   let data = result.data;

    cache = [];

    return {
      data: data,
    };
  },

  changePassword: async (userId, params) => {

    const url = `https://${env.apiDomain}/admin/change-password/${userId}`;
    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `${loginId}_${token}`,
      },
      body: JSON.stringify(params),
    });

    const result = await res.json();
console.log("Resultchangepassword",result)
    let data = result;
    return {
      data: data,
    };
  },

  uploadImage: async (dataimage) => {

    return new Promise(async (resolve, reject) => {

      let fileuri = dataimage?.src;

      if (!isEmpty(fileuri)) {

        let fileType = dataimage?.rawFile.type;
        const filename = fileuri.substring(
          fileuri.lastIndexOf("/") + 1,
          fileuri.length
        );

        const file = await fetch(fileuri)
          .then((r) => r.blob())
          .then(
            (blobFile) =>
              new File([blobFile], `${_random(0, 9999)}`, { type: fileType })
          );
       
        const loginId = localStorage.getItem("adminUserId");
        const token = localStorage.getItem("token");

        const extension = _get(
          _split(_get(file, "type"), "/"),
          "[1]",
          "jpg",
          "mp4"
        );
       
        const uniqueName = `${new Date()
          .toISOString()
          .replace(/:|\.|-/g, "")}.${extension}`;

        const {
          data: { url: s3url, fields: s3fields },
        } = await axios.post(
          `https://${env.apiDomain}/media`,
          {
            filename: uniqueName,
          },
          {
            headers: {
              Authorization: `${loginId}_${token}`,
            },
          }
        );
       
        const formData = new FormData();

        _forEach(s3fields, (value, key) => {
          
          formData.append(key, value);
        });
        formData.append("file", file);

        const { data: xml } = await axios.post(s3url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "response-content-type": "application/json",
          },
        });

        const data = convert.xml2js(xml, { compact: true, spaces: 4 });
        const remoteUrl = _get(data, "PostResponse.Location._text");
        const url = remoteUrl.split("%2F").join("/");
        resolve(url);
      }
      else {
        resolve(null);
      }
    }
    );

  },

  addAdmin: async (createadmindata) => {
  
    dataProvider.createAdmin(createadmindata).then((response) => {
     
      if (response?.result?.success) {
        if (response?.result?.data?.code) {
          switch (response?.result?.data?.code) {
            case "REFFERALCODE_EXISTS":
              alert("Referal Code Already Exist");
              break;
            case "EMAIL_EXISTS":
              alert("Email Id Already Exist");
              break;
            default:
              alert(response?.result?.data?.code);
              break;
          }
        }
        else {
          if (response?.result?.data?.type === "add") {
            alert("Successfully Add Data!");
          } else alert(response?.result?.data?.type);
        }
      }
    });
  },

  addNewPost: async (userId, params) => {
    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");

    const url = `https://${env.apiDomain}/admin/add-new-post/${userId}`;
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `${loginId}_${token}`,
      },
      body: JSON.stringify(params),
    });

    const result = await res.json();

    return result;
  },

  getAllPostFeed: async (startIndex, endIndex) => {
    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");
    const url = `https://${env.apiDomain}/posts/get-All-feed-by-admin`;
    const res = await fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        Authorization: `${loginId}_${token}`,
      },
    });

    const result = await res.json();
  
    let descdata = result?.data?.sort((a, b) => b?.updatedAt - a?.updatedAt);
    let notnulldata = descdata
      ?.filter((fltres) => Array.isArray(fltres?.media))
      .map((res) => res);

    let pageData = descdata?.slice(startIndex, endIndex);
    return notnulldata;
  },
  convertDateTime: (datetime) => {

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var cdate = moment(new Date()).format("DD");
    var pdate = moment(datetime).format("DD");
    var cmonth = moment(new Date()).format("MM");
    var pmonth = moment(datetime).format("MM");
    var daysBetween = cdate - pdate;
  
    if (pmonth < cmonth) {
      let mth = cmonth - pmonth;
     
      return `${mth} month`;
    } else {
      if (daysBetween > 0) {
        var currhours = moment(new Date()).format("hh");
        var prevhours = moment(datetime).format("hh");
        var differ = currhours - prevhours;

        return `${pdate} ${months[parseInt(pmonth - 1)]}`;
      } else {
        var currhours = moment(new Date()).format("hh");
        var prevhours = moment(datetime).format("hh");
        var differ = 0;
        if (currhours <= prevhours) {
          currhours = parseInt(currhours) + 12;
          differ = currhours - prevhours;
        } else {
          differ = currhours - prevhours;
        }
        return `${differ > 12 ? "Today" : `${differ} hr ago`} `;
      }
    }
  },

  postCommentReply: async (data) => {
   
    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");

    const url = `https://${env.apiDomain}/admin/post/${data?.postId}/comment-by-admin-reply/${data?.commentId}/reply/${data?.userId}`;
    const res = await fetch(url, {
      method: "post",
      body: JSON.stringify({
        content: data?.content,
      }),
      headers: {
        "content-type": "application/json",
        Authorization: `${loginId}_${token}`,
      },
    });

    const result = await res.json();
    return result;
  },
  addNewComment: async (data) => {
    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");

    const url = `https://${env.apiDomain}/admin/post/${data?.postId}/comment-by-admin/${data?.userId}`;
    const res = await fetch(url, {
      method: "post",
      body: JSON.stringify({
        content: data.content,
      }),
      headers: {
        "content-type": "application/json",
        Authorization: `${loginId}_${token}`,
      },
    });

    const result = await res.json();
    return result;
  },

  deletePost: async (postId, userId) => {
    const loginId = localStorage.getItem("adminUserId");
    const token = localStorage.getItem("token");
    const url = `https://${env.apiDomain}/delete-posts-by-admin/${postId}/${userId}`;
    const res = await fetch(url, {
      method: "delete",

      headers: {
        "content-type": "application/json",
        Authorization: `${loginId}_${token}`,
      },
    });

    const result = await res.json();

    return result;
  },
};

export default dataProvider;
