import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  EditButton,
  DateField,
  SelectInput,
  SelectField
} from 'react-admin';


const searchFilters = [
  <TextInput label="Search Idol Id" source="accusedId" alwaysOn />,
];

const statusOptions = [
  { value: 'processing', text: 'Processing' },
  { value: 'processed', text: 'Processed' }
]

export const UserReportList = (props) => {
  
 
  return (
    <List {...props} filters={searchFilters} title="User Reports">
      <Datagrid rowClick="show">
        <TextField source="reportId" label="Report Id" />
        <TextField source="accusedId" label="Accused Idol Id" />
        <TextField source="category" />
        <TextField source="complainerId" label="Complainer Id" />
        <TextField source="yearMonthDay" label="Report Date" />
        <SelectField source="status" choices={statusOptions} optionValue="value" optionText="text" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const UserReportShow = (props) => {
 
  return (
    <Show {...props} title="Post Report view" >
      <SimpleShowLayout>
        <TextField source="reportId" label="Report Id" />
        <TextField source="accusedId" label="Accused Idol Id" />
        <TextField source="category" />
        <TextField source="complainerId" label="Complainer Id" />
        <DateField source="yearMonthDay" label="Report Date" />
        <SelectField source="status" choices={statusOptions} optionValue="value" optionText="text" />
      </SimpleShowLayout>
    </Show>
  )
}

export const UserReportEdit = (props) => {

  return(<Edit {...props} title="User Report Edit" >
    <SimpleForm>
      <TextInput fullWidth disabled source="reportId" label="Report Id" />
      <TextInput fullWidth disabled source="accusedId" label="Accused Idol Id" />
      <TextInput source="category" />
      <TextInput fullWidth disabled source="complainerId" label="Complainer Id" />
      <DateInput fullWidth source="yearMonthDay" label="Report Date" />
      <SelectInput fullWidth source="status" choices={statusOptions} optionValue="value" optionText="text" />
    </SimpleForm>
  </Edit>
)}