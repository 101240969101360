import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  EmailField,
  RichTextField,
  ImageField,
  Labeled,
  DateField,
  SelectField,
  SelectInput,
  DateInput,
 } from 'react-admin';

import DateFieldForTimestamp from '../components/DateFieldForTimestamp';
import RichTextInput from 'ra-input-rich-text';
import { useEffect, useState } from 'react';


const searchFilters = [
  <TextInput label="Search User Id" source="userId" alwaysOn />,
  <TextInput label="Search Email" source="email" alwaysOn />,
  <TextInput label="Search DisplayName" source="displayNameLower" alwaysOn />,
];

const isactiveOptions = [
  { value: 0, text: 'No' },
  { value: 1, text: 'Yes' }
]





export const FansCustomlist = (props) => {
  const [state, setState] = useState(0)
  let menulist = [
    { name: 'Fans', status: true },
    { name: 'User Reports', status: false }
  ]
  const [menuState,setMenuState]=useState(menulist)

  useEffect(()=>{

  },[menuState,state])
  const onchange = (key) => {
     setState(key)
    menuState.forEach(element => {
      element["status"] = false
    });
    menuState[key].status = true
  }

  return (
    <>
      <div class="top-header-tab" >
        {menuState.map((item, index) =>
          <div label={item.key} className={item.status ? "top-header-tab-title active" : "top-header-tab-title"} onClick={() => onchange(index)}>
            {item.name}
          </div>

        )}
     </div>
    {state === 0 && <FanList {...props} />}
      {state===1&&<CustomUserReport  {...props}/>}
    
    </>
  )

}

const statusOptions = [
  { value: 'processing', text: 'Processing' },
  { value: 'processed', text: 'Processed' }
]
export const CustomUserReport = (props) => {
  let customprops={ ...props }
  customprops.basePath = "userReports"
  customprops.resource = "userReports"

  return (
    <List {...customprops}  filters={searchFilters} title="User Reports">
    <Datagrid rowClick="show">
      <TextField source="reportId" label="Report Id" />
      <TextField source="accusedId" label="Accused Idol Id" />
      <TextField source="category" />
      <TextField source="complainerId" label="Complainer Id" />
      <TextField source="yearMonthDay" label="Report Date" />
      <SelectField source="status" choices={statusOptions} optionValue="value" optionText="text" />
      <EditButton />
    </Datagrid>
  </List>
   
  )
}

export const FanList = (props) => {
  return (
    <List {...props} filters={searchFilters} >
      <Datagrid rowClick="show">
        <TextField source="userId" label="User Id" />
        <TextField source="displayName" />
        <EmailField source="email" />
        <TextField source="countryCode" />
        <SelectField source="isactive" choices={isactiveOptions} optionValue="value" optionText="text" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const FanShow = (props) => {
  return (
    <Show title="Fan view" {...props}>
      <SimpleShowLayout>
        <TextField source="userId" label="User Id" />
        <SelectField source="isactive" choices={isactiveOptions} optionValue="value" optionText="text" />
        <TextField source="displayName" />
        <TextField source="fullName" />
        <EmailField source="email" />
        <TextField source="gender" />
        <TextField source="phone" />
        <DateField source="birthDate" />
        <TextField source="countryCode" />
        <TextField source="language" />
        <Labeled label="Join Date">
          <DateFieldForTimestamp source="createdAt" />
        </Labeled>
        <ImageField source="avatar" />
        <RichTextField source="profile" />
      </SimpleShowLayout>
    </Show>
  )
}


export const FanEdit = (props) => {
  return (
    <Edit {...props} title="Fan Edit" >

      <SimpleForm>
        <TextInput fullWidth disabled source="userId" label="User Id" />
        <SelectInput fullWidth source="isactive" choices={isactiveOptions} optionValue="value" optionText="text" />
        <TextInput fullWidth source="displayName" />
        <TextInput fullWidth source="fullName" />
        <TextInput fullWidth source="email" type="email" />
        <TextInput fullWidth source="gender" />
        <TextInput fullWidth source="phone" />
        <DateInput fullWidth source="birthDate" />
        <TextInput fullWidth source="countryCode" />
        <TextInput fullWidth source="language" />
        <TextInput fullWidth source="avatar" />
        <RichTextInput fullWidth source="profile" />
      </SimpleForm>
    </Edit>
  )
}
