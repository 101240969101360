import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  SelectInput,
  SelectField,
  ImageField,
  DateInput,
  DateField,
  TopToolbar,
  Button,
  useRefresh,
  EmailField,
  ImageInput
} from 'react-admin';
import env from '../env';

const searchFilters = [
  <TextInput label="Search User Id" source="userId" alwaysOn />,
];

const statusOptions = [
  { value: 'reviewing', text: 'Reviewing' },
  { value: 'approved', text: 'Approved' },
  { value: 'rejected', text: 'Rejected' }
]

const paymentMethods = [
  { value: 'payoneer', text: 'Payoneer' },
  { value: 'stripe', text: 'Stripe' },
];

export const CreatorOnboardingApplicationList = (props) => {

  return (
    <List {...props} filters={searchFilters}>
      <Datagrid rowClick="show">
        <TextField source="userId" label="User Id" />
        <TextField source="creatorPageName" label="Full Name" />
        <TextField source="referralCode" />
        <SelectField source="status" choices={statusOptions} optionText="text" optionValue="value" />
        <DateField source="yearMonthDay" label="Application Date" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const CreatorOnboardingApplicationShowActions = ({ basePath, data, resource }) => {
  const refresh = useRefresh();

  const approve = async (data) => {
  
    const loginId = localStorage.getItem('adminUserId');
    const token = localStorage.getItem('token');

    const url = `https://${env.apiDomain}/admin/approve-creator-onboarding/${data?.userId}`;

    const res = await fetch(
      url,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `${loginId}_${token}`,
        },
      }
    );

    const result = await res.json();

    if (result.success) {
      alert('Approved successfully');
      refresh();
    } else {
      alert(result.code);
    }
  }

  const reject = async (data) => {
  const loginId = localStorage.getItem('adminUserId');
    const token = localStorage.getItem('token');

    const url = `https://${env.apiDomain}/admin/reject-creator-onboarding/${data.userId}`;

    const res = await fetch(
      url,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `${loginId}_${token}`,
        },
      }
    );

    const result = await res.json();


    if (result.success) {
      alert('Rejected successfully');
      refresh();
    } else {
      alert(result.code);
    }
  }

  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      <Button color="primary" onClick={() => approve(data)} label="Approve" />
      <Button color="primary" onClick={() => reject(data)} label="Reject" />
    </TopToolbar>
  );
}

const PreviewImage = ({ record, source }) => {
  if (typeof (record) == "string") {
        record = {
            [source]: record
        }
    }
 
 return (
    <ImageField record={record} source={source} />
    )
  }
  
export const CreatorOnboardingApplicationShow = (props) => {

  return (
    <Show {...props} title="Creator Onboarding Application View" actions={<CreatorOnboardingApplicationShowActions />} >
      <SimpleShowLayout>
        <TextField source="userId" label="User Id" />
        <TextField source="creatorPageName" label="Full Name" />
        <TextField source="countryCode" />
        <TextField source="creationContentDescription" />
        <TextField source="creatorPageDescription" />
        <TextField source="fanshipPageName" />
      
       <ImageField source="identityProof" /> 
        <TextField source="instagramUsername" />
        <ImageField source="profileImage" />
        <SelectField source="receivePaymentMethod" choices={paymentMethods} optionText="text" optionValue="value" />
        <EmailField source="payoneerEmail" />
        <TextField source="referralCode" />
        <SelectField source="status" choices={statusOptions} optionText="text" optionValue="value" />
        <DateField source="yearMonthDay" label="Application Date" />
      </SimpleShowLayout>
    </Show>
  )
}

export const CreatorOnboardingApplicationEdit = (props) => {

  return (

    <Edit {...props} title="Creator Onboarding Application Edit" >
      <SimpleForm>
        <TextInput fullWidth disabled source="userId" label="User Id" />
        <TextInput fullWidth source="creatorPageName" label="Full Name" />
        <TextInput fullWidth source="countryCode" />
        <TextInput fullWidth source="creationContentDescription" />
        <TextInput fullWidth source="creatorPageDescription" />
        <TextInput fullWidth source="fanshipPageName" />
        <ImageInput  source="identityProof" label="identityProof" accept="image/*" placeholder={<p>Please Select Your IdentityProof</p>}> 
           <PreviewImage source="src" /> 
       </ImageInput>
       
        <TextInput fullWidth source="instagramUsername" />
      
        <ImageInput  source="profileImage" label="profileImage" accept="image/*" placeholder={<p>Please Select Your Profile Image</p>}> 
           <PreviewImage source="src" /> 
       </ImageInput>
        <SelectInput fullWidth source="receivePaymentMethod" choices={paymentMethods} optionText="text" optionValue="value" />
        <TextInput fullWidth source="payoneerEmail" type="email" />
        <TextInput fullWidth source="referralCode" />
        <SelectInput fullWidth source="status" choices={statusOptions} optionText="text" optionValue="value" />
        <DateInput source="yearMonthDay" label="Application Date" />
      </SimpleForm>
    </Edit>
  )

}