import cardImage from "../assets/cardImage.jpg";
import avatarimg from "../assets/ic_creator.png";
import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import "react-awesome-slider/dist/styles.css";
import dataProvider from "src/dataProvider";
import { TextInput } from "react-admin";
export const CommentLikeReply = ({
  avataritem,
  username,
  comment,
  publishdate,
  replydata,
  isTopLayer,
  likeCount
}) => {

  const [commentstatus, setCommentstatus] = useState(false);

  const onSetReply = () => {
    setCommentstatus(!commentstatus);
 };
  return (
    <div>
      <div
        style={{
          borderBottomWidth: 1,
          height: "100%",
          borderBottomColor: "#DEE2E6",
          marginBottom: "15px",
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div className="comments-avatar">
            <img
              src={avataritem ? avataritem : avatarimg}
              style={{ height: "40px", width: "40px", borderRadius: "16px" }}
            />
          </div>
          <div className="child-comments">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <label
                style={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontWeight: "700",
                  color: "#212529",
                }}
              >
                {username}
              
              </label>
              <label
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: "400",
                  color: "#6C757D",
                }}
              >
                {dataProvider.convertDateTime(publishdate)}
              </label>
            </div>
            <div className="comment-section-reply">
              {comment}
              {/* <FavoriteOutlinedIcon color="red" /> */}
            </div>
       
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <label
                    style={{
                      fontSize: "12px",
                      lineHeight: "18px",
                      fontWeight: "700",
                      color: "#6C757D",
                      marginRight: "15px",
                    }}
                  >
                   {likeCount} Like
                  </label>
                  <label
                    style={{
                      fontSize: "12px",
                      lineHeight: "18px",
                      fontWeight: "700",
                      color: "#6C757D",
                      marginRight: "15px",
                    }}
                    onClick={() => onSetReply()}
                  >
                    Reply
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                  }}
                >
                  <FavoriteBorderIcon className="comment-right-icon-svg" />
                  <MoreHorizOutlinedIcon className="comment-right-icon-svg" />
                </div>
              </div>
            </div>
        
            {commentstatus && <ReplyCmp avataritem={avataritem} username={username} replydata={replydata} onSetReply={onSetReply} />}
          </div>
        </div>
      </div>
    </div>
  );
};
export const ReplyCmp = ({ avataritem, username, replydata,onSetReply }) => {
 
  const [inputstate, setInputstate] = useState(null);
  const onReply = (e) => {
  
    if (e.keyCode === 13) {
   
      let data = {
        userId: replydata?.userId,
        postId: replydata?.postId,
        commentId: replydata?.commentId,
        content: inputstate
      }
      dataProvider.postCommentReply(data).then(commentres => {
     
        if(commentres.success){
          setInputstate(null)
          onSetReply()
        }
      })
    
    }
  }
  return (
    <div
      style={{
        display: "flex",
        marginLeft: 30,
      }}
    >
      <label
        style={{
          fontSize: "12px",
          lineHeight: "18px",
          fontWeight: "700",
          color: "#6C757D",
          marginRight: "15px",
        }}
      >
        <img
          src={avataritem ? avataritem : avatarimg}
          style={{ height: "40px", width: "40px", borderRadius: "16px" }}
        />
      </label>
      <label
        style={{
          fontSize: "12px",
          lineHeight: "18px",
          fontWeight: "700",
          color: "#6C757D",
          flex: 1,
        }}
      // onClick={() => onSetReply()}
      >
        <input
          className="inputcss"
          placeholder={username}
          onChange={(e) => setInputstate(e.target.value)}
          onKeyDown={(e) => onReply(e)}
        />
      </label>
    </div>
  );
};

export const  AddCommentCmp = ({avataritem,username}) => {
 
  const [inputstate, setInputstate] = useState(null);
  const onReply = (e) => {
   if (e.keyCode === 13) {
    setInputstate("")
    }
  }
  return (
    <div
      style={{
        display: "flex",
        marginLeft: 30,
      }}
    >
      <label
        style={{
          fontSize: "12px",
          lineHeight: "18px",
          fontWeight: "700",
          color: "#6C757D",
          marginRight: "15px",
        }}
      >
        <img
          src={avataritem ? avataritem : avatarimg}
          style={{ height: "40px", width: "40px", borderRadius: "16px" }}
        />
      </label>
      <label
        style={{
          fontSize: "12px",
          lineHeight: "18px",
          fontWeight: "700",
          color: "#6C757D",
          flex: 1,
        }}
      // onClick={() => onSetReply()}
      >
        <input
          className="inputcss"
          value={inputstate}
          placeholder={username}
          onChange={(e) => setInputstate(e.target.value)}
          onKeyDown={(e) => onReply(e)}
        />
      </label>
    </div>
  );
};

