import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
  EditButton,
  DateField,
  Create,
  TopToolbar,
  CreateButton,
  ExportButton,
  SelectInput,
  SelectField
} from 'react-admin';

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const searchFilters = [
  <TextInput label="Search Ref Number" source="referenceNumber" alwaysOn />,
  <TextInput label="Search User Id" source="userId" alwaysOn />
];

const paymentMethods = [
  { value: 'payoneer', text: 'Payoneer' },
  { value: 'stripe', text: 'Stripe' },
];

export const PaymentList = (props) => {
  return (
    <List {...props} filters={searchFilters} actions={<ListActions />}>
      <Datagrid rowClick="show">
        <TextField source="referenceNumber" />
        <TextField source="userId" label="User Id" />
        <NumberField source="amount" />
        <SelectField fullWidth source="paymentMethod" choices={paymentMethods} optionText="text" optionValue="value" />
        <DateField source="transactionDate" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const PaymentShow = (props) => {
  return (
    <Show title="Payment view" {...props}>
      <SimpleShowLayout>
        <TextField source="referenceNumber" />
        <TextField source="userId" label="User Id" />
        <NumberField source="amount" />
        <SelectField fullWidth source="paymentMethod" choices={paymentMethods} optionText="text" optionValue="value" />
        <DateField source="transactionDate" />
      </SimpleShowLayout>
    </Show>
  )
}

export const PaymentEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput fullWidth disabled source="referenceNumber" />
      <TextInput fullWidth disabled source="userId" label="User Id" />
      <NumberInput fullWidth source="amount" />
      <SelectInput fullWidth source="paymentMethod" choices={paymentMethods} optionText="text" optionValue="value" />
      <DateInput fullWidth source="transactionDate" />
    </SimpleForm>
  </Edit>
);

export const PaymentCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput fullWidth source="userId" label="User Id" />
      <NumberInput fullWidth source="amount" />
      <SelectInput fullWidth source="paymentMethod" choices={paymentMethods} optionText="text" optionValue="value" />
      <DateInput fullWidth source="transactionDate" />
    </SimpleForm>
  </Create>
);