import {
  DateField,
} from 'react-admin';

const DateFieldForTimestamp = props => {
  let timestamp = props.record[props.source] < 1000000000000 ? props.record[props.source] * 1000 : props.record[props.source]

  const recordWithTimestampAsInteger = {
    [props.source]: timestamp
  };

  return (
    <DateField {...props} record={recordWithTimestampAsInteger} />
  )
}

export default DateFieldForTimestamp;