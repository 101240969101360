                import {
                List,
                Datagrid,
                TextField,
                NumberField,
                Show,
                SimpleShowLayout,
                Edit,
                SimpleForm,
                TextInput,
                NumberInput,
                EditButton,
                EmailField,
                RichTextField,
                ImageField,
                Labeled,
                DateField,
                SelectField,
                SelectInput,
                DateInput,
                TopToolbar,
                FilterButton,
                CreateButton,
                ExportButton,
                Create,
                ImageInput,
                useListContext,
                Pagination as RaPagination,
                PaginationActions as RaPaginationActions,
                } from 'react-admin';
                import {useState,useEffect} from 'react';
                import DateFieldForTimestamp from '../components/DateFieldForTimestamp';
                import { InputAdornment } from '@mui/material';
                import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
                import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
                import { useHistory } from "react-router-dom"
                import { required, validateEmail, validatePhoneNumber, validateReferalcode, fullnameString, requiredWithMessage, receivePaymentValidate, languageValidate, dobValidate, genderValidate } from 'src/components/validateField';
                import { CountriesList } from 'src/contants/Countries';
                import { LanguagesList } from 'src/contants/Language';





                const searchFilters = [
                <TextInput label="Search User Id" source="userId" alwaysOn />,
                <TextInput label="Search Email" source="email" alwaysOn />,
                <TextInput label="Search DisplayName" source="displayName" alwaysOn />,
                ];


                const isactiveOptions = [
                { value: 0, text: 'No' },
                { value: 1, text: 'Yes' }
                ]

                const paymentMethods = [
                { value: 'payoneer', text: 'Payoneer' },
                { value: 'stripe', text: 'Stripe' },
                ];



                const genderMethod= [
                { value: 'M', text: 'Male' },
                { value: 'F', text: 'Female' },
                ];


                export const CreatorsCustomlist = (props) => {
                let menulist = [
                { name: 'Creator',status:true},
                { name: 'Creator Onboarding',status:false}
                ]
                const [state, setState] = useState(0)
                const [menuState,setMenuState]=useState(menulist)

                useEffect(()=>{

                },[state,menuState])
                const onchange = (key) => {
                setState(key)
                menuState.forEach(element => {
                element["status"] = false
                });
                menuState[key].status=true
                }

                return (
                <>
                <div class="top-header-tab" >
                {menuState?.map((item, index) =>
                <div label={item.key} className={item.status?"top-header-tab-title active":"top-header-tab-title"}   onClick={() => onchange(index)}>
                {item.name}
                </div>

                )}

                </div>

                {state === 0 && <CreatorList {...props} />}
                {state === 1 &&
                <CreatorOnboardingApplicationList {...props}/>
                }

                </>
                )

                }


                const ListActions = () => (
                <TopToolbar>
                <CreateButton label='NEW CREATOR' onClick={()=><AddNewCreator/>}/>
                <ExportButton/>
                </TopToolbar>
                );



                let count=0;
                const PaginationActions = props => {
                let customprops={ ...props }
                if(count==0){
                count=customprops.count 
                }
                customprops.count=count
                return( <RaPaginationActions
                {...customprops} color="primary"/>
                );
                }

                export const Pagination = props =>{
                let customprops={ ...props }

                return(
                <RaPagination {...customprops} ActionsComponent={PaginationActions} />
                )
                }
                export const CreatorList = (props) => {

                return (
                <List {...props} filters={searchFilters} actions={<ListActions/>} pagination={<Pagination />} >

                <Datagrid rowClick="show" className='follower-count-creator'>
                <TextField source="userId" label="User Id" />
                <TextField source="displayName" />
                <EmailField source="email" />
                <TextField source="countryCode" />
                <TextField source="fanshipPageName" />
                <NumberField source="followerCount" className='folloer-count-creator'/>
                <SelectField source="isactive" choices={isactiveOptions} optionValue="value" optionText="text" />
                <EditButton />
                </Datagrid>
                </List>
                )
                }
                export const ChangePassword=(props)=>{
                return(
                <Show {...props}  title="Creator view" >
                <SimpleForm>
                <TextInput fullWidth source="username"  label="Display Name"/>
                <TextInput fullWidth source="fullName"  label="Full Name"/>
                <TextInput fullWidth source="email" type="email" label="Email"/>
                <TextInput fullWidth source="password" type="password" label="Password"/>
                <TextInput fullWidth source="confirmpassword" type="password" label="Confirm Password"/>
                <TextInput fullWidth source="gender"  label="Gender"/>
                <TextInput fullWidth source="Phone"  label="Phone"/>
                <DateInput fullWidth source="birthDate" label="Birth date"/>
                <TextInput fullWidth source="fanshipPageName" label="Fanship page name"/>
                <TextInput fullWidth source="countryCode" label="Country Code"/>
                <TextInput fullWidth source="instagramUsername" label="Instagram Username" />
                <TextInput fullWidth source="language" lable="Language"/>
                <SelectInput fullWidth source="receivePaymentMethod" choices={paymentMethods} optionValue="value" optionText="text" />
                <TextInput fullWidth source="payoneerEmail" type="payoneerEmail" label="Payoneer Email" />
                <TextInput fullWidth source="referralCode"  label="Referral Code"/> 
                <TextInput fullWidth source="avatar" />
                <TextInput fullWidth source="profile"  label="Profile" />
                <TextInput fullWidth source="profileImage" label="Profile image"/>

                </SimpleForm>
                </Show>
                )
                }


                const ShowActions=(props)=>{
                const history = useHistory()

                return(
                <TopToolbar>
                <EditButton label='CHANGE PASSWORD' onClick={()=>history.push(`/ChangePassword/${props.userId}`)}/> 
                {/* <EditButton onClick={()=> history.goBack("/", { replace: true })}/> */}
                <EditButton  onClick={()=>history.push(`/creators/${props.userId}`)}/> 
                </TopToolbar>
                )
                }
                export const CreatorShow = (props) => {

                return (
                <Show title="Creator view" {...props} actions={<ShowActions userId={props.id} {...props}/>}>

                <SimpleShowLayout>
                <TextField source="userId" label="User Id" />
                <SelectField source="isactive" choices={isactiveOptions} optionValue="value" optionText="text" />
                <TextField source="displayName" />
                <TextField source="fullName" />
                <EmailField source="email" />
                <TextField source="gender" />
                <TextField source="phone" />
                <DateField source="birthDate" />
                <TextField source="fanshipPageName" />
                <TextField source="countryCode" />
                <NumberField source="followerCount" />
                <TextField source="instagramUsername" />
                <TextField source="language" />
                <SelectField source="receivePaymentMethod" choices={paymentMethods} optionValue="value" optionText="text" />
                <EmailField source="payoneerEmail" />
                <TextField source="referralCode" fullWidth/>
                <Labeled label="Join Date">
                <DateFieldForTimestamp source="createdAt" />
                </Labeled>
                <ImageField source="avatar" />
                <RichTextField source="profile" />
                <ImageField source="profileImage" />
                </SimpleShowLayout>
                </Show>
                )
                }

                export const CreatorEdit = (props) => {

                return (
                <>

                <Edit {...props} title="Creator Edit" >
                <SimpleForm>
                <TextInput fullWidth disabled source="userId" label="User Id" />
                <SelectInput fullWidth source="isactive" choices={isactiveOptions} optionValue="value" optionText="text" />
                <TextInput fullWidth source="username" />
                <TextInput fullWidth source="displayName" />
                <TextInput fullWidth source="fullName" />
                <TextInput fullWidth source="email" type="email" />
                <TextInput fullWidth source="gender" />
                <TextInput fullWidth source="phone" />
                <DateInput fullWidth source="birthDate" />
                <TextInput fullWidth source="fanshipPageName"
                   validate={requiredWithMessage('Enter Fanship Page Name')}  
                />
                <TextInput fullWidth source="countryCode" />
                <NumberInput fullWidth source="followerCount" disabled />
                <TextInput fullWidth source="instagramUsername" />

                <TextInput fullWidth source="creatorPageName" 
                validate={requiredWithMessage('Enter Creator Page Name')}  
                label="Creator Page Name"/>



                <TextInput fullWidth source="creatorPageDescription" 
                // validate={requiredWithMessage('Enter Creator Page Description')} 
                 label="Creator Page Description"/>


                <TextInput fullWidth source="creationContentDescription" 
                // validate={requiredWithMessage('Enter Creation Content Description')}
                  label="Creation Content Description"/>

                <SelectInput fullWidth source="receivePaymentMethod" 
                choices={paymentMethods} optionValue="value" optionText="text" />
                <TextInput fullWidth source="payoneerEmail" type="email" />
                <TextInput source="referralCode" fullWidth />
                <ImageInput  source="avatar" label="avatar" accept="image/*" placeholder={<p>Please Select Avatar Image</p>}> 
                <PreviewImage source="src" /> 
                </ImageInput> 


                <ImageInput  source="profileImage" label="profileImage" accept="image/*" placeholder={<p>Please Select Profile Image</p>}> 
                <PreviewImage source="src" /> 
                </ImageInput> 
                <ImageInput  source="identityProof" label="identityProof" accept="image/*" placeholder={<p>Please Select Your Identity Proof</p>}> 
                <PreviewImage source="src" /> 
                </ImageInput>  
                </SimpleForm>
                </Edit>
                </>
                )
                }




                const PreviewImage = ({ record, source }) => {
                if (typeof (record) == "string") {
                record = {
                [source]: record
                }
                }

                return (
                <ImageField record={record} source={source} />
                )
                }


                export const AddNewCreator=(props)=>{
                let initialdata={ username: "",fullName:"",avatar:"",profileImage:""}

                const customprops = { ...props }

                customprops.resource = "addNewAdmin"
                // customprops?.params?.data?.avatar=

                const postDefaultValue = () => (initialdata);
                const [visible, setVisible] = useState(false);
                const [confirmpasswordvisible,setConfirmpasswordvisible]=useState(false)

                const toggleVisibility = () => {
                setVisible(!visible);
                };
                return(
                <Create 
                {...customprops}
                title="Creator Create">

                <SimpleForm   
            
                initialValues={postDefaultValue} >
                  
                <TextInput  fullWidth source="username"
           
                validate={requiredWithMessage("Enter Your User name")}
                label="User Name"   placeholder='Please Enter Your User Name' />

                <TextInput  fullWidth source="displayName" 
                validate={requiredWithMessage("Enter Your display name")}
                label="Display Name"   placeholder='Please Enter Your Display Name'/>
                <TextInput fullWidth source="fullName"  validate={fullnameString} label="Full Name"/>
                <TextInput fullWidth source="email" validate={validateEmail}  type="email" label="Email"/>
                <TextInput fullWidth source="password"  
                validate={requiredWithMessage("Enter Your Password")}
                type={visible ? 'text' : 'password'} label="Password"
                InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                <div onClick={toggleVisibility}>
                {visible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </div>
                </InputAdornment>
                )
                }}

                />
                <TextInput fullWidth source="confirmpassword" 
                validate={requiredWithMessage("Enter Your Confirm Password")}
                type={confirmpasswordvisible ? 'text' : 'password'} 
                label="Confirm Password"
                InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                <div onClick={()=>setConfirmpasswordvisible(!confirmpasswordvisible)}>
                {confirmpasswordvisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon/>}
                </div>
                </InputAdornment>
                )
                }}
                />
                <SelectInput fullWidth source="gender" choices={genderMethod} 
                // validate={genderValidate} 
                optionValue="value" optionText="text"   />
                <TextInput fullWidth source="Phone" 
                // validate={validatePhoneNumber} 
                label="Phone"/>
                <DateInput fullWidth source="birthDate"  
                // validate={dobValidate} 
                label="Birth date"/>


                <TextInput fullWidth source="creatorPageName" 
                validate={requiredWithMessage('Enter Creator Page Name')}  
                label="Creator Page Name"/>



                <TextInput fullWidth source="creatorPageDescription" 
                // validate={requiredWithMessage('Enter Creator Page Description')} 
                label="Creator Page Description"/>


                <TextInput fullWidth 
                source="creationContentDescription" 
                // validate={requiredWithMessage('Enter Creation Content Description')}
                label="Creation Content Description"/>

                <TextInput fullWidth source="fanshipPageName" 
                validate={requiredWithMessage('Enter Fanship Page Name')}  
                label="Fanship page name"/>
                <SelectInput fullWidth source="countryCode" label="Country  Name" 
                validate={requiredWithMessage("Select Your Country Name")}
                choices={CountriesList} optionValue="code" optionText="name"   />

                <TextInput fullWidth source="instagramUsername" 
                // validate={requiredWithMessage("Enter Your Instagram User Name")} 
                label="Instagram Username" />

                {/* <TextInput fullWidth source="language" validate={languageValidate}  lable="Language"/> */}

                {/* <SelectInput fullWidth source="language" label="Language" 
                // validate={requiredWithMessage("Select Your Language")}
                choices={LanguagesList} optionValue="code" optionText="name"   /> */}

                <SelectInput fullWidth source="receivePaymentMethod" 
                //  validate={receivePaymentValidate}
                choices={paymentMethods} optionValue="value" optionText="text" />
                <TextInput fullWidth source="payoneerEmail" 
                //  validate={requiredWithMessage("Enter Your Payoneer Email")}
                type="payoneerEmail"
                label="Payoneer Email" />
                <TextInput fullWidth source="referralCode" 
                //  validate={validateReferalcode}  
                label="Referral Code"/> 
                <ImageInput  source="avatar" label="avatar" accept="image/*" placeholder={<p>Please Select Avatar Image</p>}> 
                <PreviewImage source="src" /> 
                </ImageInput> 


                <ImageInput  source="profileImage" label="profileImage" accept="image/*" placeholder={<p>Please Select Your Profile Image</p>}> 
                <PreviewImage source="src" /> 
                </ImageInput>

                <ImageInput  source="identityProof" label="identityProof" accept="image/*" placeholder={<p>Please Select Your Identity Proof</p>}> 
                <PreviewImage source="src" /> 
                </ImageInput>  
                </SimpleForm>
                </Create>
                )
                }


                const searchFilters1 = [
                <TextInput label="Search User Id" source="userId" alwaysOn />,
                ];

                const statusOptions1= [
                { value: 'reviewing', text: 'Reviewing' },
                { value: 'approved', text: 'Approved' },
                { value: 'rejected', text: 'Rejected' }
                ]

                const paymentMethods1= [
                { value: 'payoneer', text: 'Payoneer' },
                { value: 'stripe', text: 'Stripe' },
                ];


                const CreatorListActions = () => (
                <TopToolbar>

                <ExportButton/>
                </TopToolbar>
                );
                export const CreatorOnboardingApplicationList = (props) => {
                let customprops={ ...props }
                customprops.basePath = "creatorOnboardingApplications"
                customprops.resource = "creatorOnboardingApplications"

                return (
                <List {...customprops} filters={searchFilters1}  actions={<CreatorListActions/>} >
                <Datagrid rowClick="show">
                <TextField source="userId" label="User Id" />
                <TextField source="creatorPageName" label="Full Name" />
                <TextField source="referralCode" />
                <SelectField source="status" choices={statusOptions1} optionText="text" optionValue="value" />
                <DateField source="yearMonthDay" label="Application Date" />
                <EditButton  />
                </Datagrid>
                </List>
                )
                }

