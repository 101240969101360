import { Avatar, Card } from "@mui/material";

import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import TurnedInOutlinedIcon from "@mui/icons-material/TurnedInOutlined";

import Delete from "../assets/Delete.svg";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import dataProvider from "src/dataProvider";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { CommentLikeReply } from "src/components/CommentLikeReply";
import { isEmpty } from "src/components/customfunction";
import {
  Button,
  Confirm,
  useListContext,
  useUpdateMany,
} from "react-admin";
import { PostAddTwoTone } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';


const UniversalFeed = () => {
  const [postdata, setPostdata] = useState([])
  const [deletestatus, setDeletestatus] = useState(false)
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const [confirmstatus,setConfirmstatus]=useState(false)
 const [deleteitem,setDeleteItem]=useState(null)

  const [updateMany, { isLoading }] = useUpdateMany(
    'posts',
    { ids: selectedIds, data: { views: 0 } }
  );

 
  const getdata = () => {
  
    dataProvider.getAllPostFeed(0, 4).then((res) => {
      setPostdata(res);
    });
  }
  useEffect(() => {
    getdata()
  }, []);
  const onSearch = (value) => {
    if (!isEmpty(value)) {
     
      const newdata = postdata?.filter((item) => {
      
        const userId = item?.userId.toUpperCase()
        const postId = item?.postId.toUpperCase()
        const searchvalue = value.toUpperCase()
        return (userId.indexOf(searchvalue) || postId.indexOf(searchvalue)) > -1
      })
      setPostdata(newdata)
    }
    else {
      getdata()
    }
  }
  const onDeleteStatus = (index) => {
  let tempdata=postdata
   if(tempdata[index].deletestatus==true){
   tempdata[index].deletestatus=false
    }else{
      tempdata[index].deletestatus=true
     
    }
  setPostdata(tempdata)
   setDeletestatus(!deletestatus)
  
  }
 
const onDelete=(deleteitem)=>{
setDeleteItem(deleteitem)
setConfirmstatus(true)
setOpen(true)
    if(confirmstatus===true && open===true)
    {
     dataProvider.deletePost(deleteitem?.postId,deleteitem?.userId).then(deleteres=>{
       if(deleteres.success){
       getdata()
      setDeletestatus(!deletestatus)
      setOpen(false)
      setConfirmstatus(false)
      setDeleteItem(null)
   }
  })
   
    }
  
}
const handleConfirm = () =>{ 
 
  onDelete(deleteitem)
 
}
const handleDialogClose = () =>{
  setOpen(false)
  setConfirmstatus(false)
  
}
  

  return (
    <div
      style={{
        justifyContent: "center",
        height: "100%",
        width: "100%",
        alignItems: "center",
      }}>
         <Confirm
                isOpen={confirmstatus}
               title=""
                content="Are you sure you want to update these posts?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <div className="inputsearchcss">
      <SearchIcon/>
        <input onChange={(e) => onSearch(e.target.value)} placeholder="Search for User Id or Post Id " style={{borderWidth:0}} />
        </div>
      </div>
      {postdata?.map((item,index) => (
        <div style={{ marginBottom: '20px', marginTop: '20px' }}>
          <Box>
            <Card className="feed-card">
              <div className="post-feed">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: 'flex' }}>
                    <Avatar
                      alt={item?.idol?.displayName}
                      src={item?.idol?.avatar}
                    />
                    <div className="feed-country-day">
                      <label>{item?.idol?.displayName}</label>
                      <span>
                        {dataProvider.convertDateTime(item.updatedAt)}{" "}
                        <span>
                          <span className="dot-days"></span>
                          {item.idol?.countryName
                            ? item.idol?.countryName
                            : item.idol?.countryCode}
                        </span>
                      </span>
                      <span>
                      </span>
                    </div>
                  </div>
                  <div className="deleteContainer" onClick={() => onDeleteStatus(index)}>
                    <MoreHorizIcon />
                  </div>
                </div>
                <div className="description-p">
                  <p>{item?.description}</p>
                </div>
               
                {item?.deletestatus &&
                  <Box className="delete-box" onClick={()=>onDelete(item)}>
                    <img src={Delete} /> Delete
                  </Box>
                }
              </div>
              <div>
                <div>
                  <AwesomeSlider>
                    {item?.media?.map((item) => (
                      <div data-src={item} />
                    ))}
                  </AwesomeSlider>
                </div>
                <div className="main-feed-like-comments">
                  <div className="feed-like-save" style={{ display: item?.comments?.length > 0 ? 'flex' : 'none' }}>
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                      <FavoriteBorderIcon color="#000" />
                      <label>{item?.likeCount}</label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 15,
                      }}
                    >
                      <ForumOutlinedIcon color="#000" />
                      <label>{item?.commentCount}</label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 15,
                      }}
                    >
                      <TurnedInOutlinedIcon color="#000" />
                    </div>
                  </div>
                  {/* pending work */}
                  {item?.comments?.slice(0, 2)?.map((commentitem) =>
                    <>
                      <CommentLikeReply
                        likeCount={commentitem?.likeCount}
                        replydata={{ userId: item?.userId, postId: item?.postId, commentId: commentitem?.commentId }}
                        avataritem={item?.idol?.avatar}
                        username={item?.idol?.displayName}
                        comment={commentitem?.content}
                        publishdate={commentitem?.publishTime}
                        isTopLayer={commentitem?.isTopLayer}
                      />
                      {commentitem?.childComments?.length > 0 && commentitem?.childComments?.map((childcommentres) =>
                        <div style={{ marginLeft: 50 }}>
                          <CommentLikeReply
                            likeCount={childcommentres?.likeCount}
                            avataritem={childcommentres?.userDetail?.avatar} username={childcommentres?.userDetail?.displayName}
                            comment={childcommentres?.content} publishdate={childcommentres?.publishTime}
                            isTopLayer={childcommentres?.isTopLayer}
                     
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Card>
          </Box>
        </div>
      ))}
    </div>
  );
};
export default UniversalFeed;