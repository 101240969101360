import {
  SimpleForm,
  TextInput,
  ImageField,
  SelectInput,
  Button,
  FileInput,
  useRefresh
} from "react-admin";

import React, { useState, useEffect } from "react";

import dataProvider from "src/dataProvider";

import uploadimg from "../assets/uploadimg.png";
import plus from "../assets/plus.png";
import { requiredWithMessage } from "src/components/validateField";
import CancelIcon from "@mui/icons-material/Cancel";


const postTypeMethod = [
  { value: "image", text: "Image" },
  { value: "video", text: "Video" },
];

const accessTypeMethod = [
  { value: 1, text: "Public" },
  { value: 2, text: "Private" },
];
const tierTypeMethod = [
  { value: "0000", text: "Free Plan" },
  // { value: '1111', text: '11111' },
];

export const NewPost = (props) => {
  const { userId } = props.match.params;
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [postType, setPostType] = useState(postTypeMethod[0].value);
  const [tier, setTier] = useState("0000");
  const [access, setAccess] = useState(1);
  const [media, setMedia] = useState();
  const [imageCollection, setImageCollection] = useState([]);
  const [videoCollection, setVideoCollection] = useState([]);
  const [decisionFlag, setDecisionFlag] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const form = SimpleForm();
 

  let imagerecord;

  useEffect(() => {
    
    (imageCollection.length > 0 || videoCollection.length > 0)&&description?.length>0
      ? setDecisionFlag(true)
      : setDecisionFlag(false);
  }, [imageCollection, videoCollection, isloading,description]);
  const PreviewImage = ({ record, source }) => {
  
    if (typeof record == "string") {
      record = {
        [source]: record,
      };
    }
    imagerecord = record;

    return postType === "video" ? (
      <video src={record.src} />
    ) : (
     
      <ImageField source={source} record={record} />
    );
  };

  const onRemove = async (index) => {

    if (postType === "image") {
      var imgdata = imageCollection.filter((item, i) => i !== index);
      setImageCollection(imgdata);
    } else {
      var videodata = videoCollection.filter((item, i) => i !== index);
      setVideoCollection(videodata);
    }
  };

  const onDrop = async () => {
    if (postType === "video") {
      setImageCollection([]);
      setIsloading(true);
      dataProvider.uploadImage(imagerecord).then((res) => {
        setVideoCollection((videoCollection) => [...videoCollection, res]);
        setIsloading(false);
      });
    } else {
      setVideoCollection([]);
      setIsloading(true);
      let imageresponse = await dataProvider.uploadImage(imagerecord);
      setImageCollection((imageCollection) => [
        ...imageCollection,
        imageresponse,
      ]);
      setIsloading(false);
    }
  };

  const onSubmit = () => {
 
    let params = {
      title: title,
      description: description,
      postType: postType,
      selectTier: [tier],
      access: access,
      publishStatus: 2,
      media: postType === "video" ? videoCollection : imageCollection,
    };
    dataProvider.addNewPost(userId, params).then((res) => {
      if (res.success) {
        alert("Successfully Add");
        setTitle(null);
        setDescription(null);
        setPostType("image");
        setTier(null);
        setAccess(null);
        setMedia(null);
        setImageCollection([]);
        setVideoCollection([]);
        setDecisionFlag(true);
        window.location.reload(false)
      } else {
        setDecisionFlag(false);
        alert("Something went wrong");
      }
    });
  };


  
  return (
    <div>
      <SimpleForm className="formsimpleform" toolbar={false} 
      // validateUserCreation={validateUserCreation}
      >
        <TextInput
          disabled
          fullWidth
          defaultValue={userId}
          validate={requiredWithMessage("Enter  User Id")}
          source="userId"
          label="User Id"
        />

        <TextInput
          fullWidth
          defaultValue={title}
          // validate={requiredWithMessage("Enter Post Title")}
          source="title"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextInput
          fullWidth
          validate={requiredWithMessage("Enter Post Description")}
          source="description"
          label="Description *"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <SelectInput
          fullWidth
          defaultValue={postTypeMethod[0].value}
          value={postType}
          source="postType"
          validate={requiredWithMessage("Select Your Post Type")}
          choices={postTypeMethod}
          optionValue="value"
          optionText="text"
          onChange={(e) => setPostType(e.target.value)}
        />

        <SelectInput
          fullWidth
          defaultValue={accessTypeMethod[0].value}
          value={access}
          validate={requiredWithMessage("Select Your Access ")}
          onChange={(e) => setAccess(e.target.value)}
          source="Access"
          choices={accessTypeMethod}
          optionValue="value"
          optionText="text"
        />

        <SelectInput
          fullWidth
          defaultValue={tierTypeMethod[0].value}
          value={tier}
          validate={requiredWithMessage("Select Your Tier ")}
          onChange={(e) => setTier(e.target.value)}
          label="Select Tier"
          source="tier"
          choices={tierTypeMethod}
          optionValue="value"
          optionText="text"
        />
        <div className="new-post-image-none">
          <FileInput
            source="preview"
            multiple={true}
            accept={postType === "video" ? "video/*" : "image/*"}
            placeholder={
              <div style={{ textAlign: "start" }}>
                <img src={uploadimg} style={{ height: 20, width: 20 }} />
                <label className="upload">UPLOAD</label>
              </div>
            }
            options={{ onDrop: () => onDrop() }}
          >
            <PreviewImage source="src" />
          </FileInput>
        </div>

        <div style={{ display: "flex", marginBottom: 30 }}>
          {postType === "image" &&
            imageCollection?.map((item, index) => (
              <div
                style={{
                  height: 150,
                  width: 150,
                  borderWidth: 1,
                  marginRight: 20,
                }}
              >
                <div
                  onClick={() => onRemove(index)}
                  style={{ position: "absolute", zIndex: 7 }}
                >
                  <CancelIcon style={{ color: "#c35a14" }} />
                </div>
                
                  <img src={item} style={{ height: 150, width: 150 }} />
              
              </div>
            ))}
        </div>
        <div style={{ display: "flex" }}>
          {postType === "video" &&
            videoCollection?.map((item, index) => (
              <div
                style={{
                  height: 150,
                  width: 150,
                  borderWidth: 1,
                  marginRight: 20,
                }}
              >
                <div
                  onClick={() => {
                    onRemove(index);
                  }}
                  style={{ position: "absolute", zIndex: 7 }}
                >
                  <CancelIcon style={{ color: "#c35a14" }} />
                </div>
                {isloading ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {"loading..."}
                  </div>
                ) : (
                  <video src={item} style={{ height: 150, width: 150 }} />
                )}
              </div>
            ))}
        </div>

        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            backgroundColor: "#F8F9FA",
            height: 42,
          }}
        >
          <Button
            onClick={() => (decisionFlag ? onSubmit() : null)}
            className={decisionFlag === true ? "textcolor" : "deactivebutton"}
          >
            <>
              <img src={plus} className="iconbutton" />
              <label className="buttontext">PUBLISH</label>
            </>
          </Button>
        </div>
      </SimpleForm>
    </div>
  );
};
