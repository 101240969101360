import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  Labeled,
  SelectInput,
  SelectField,
  NumberField,
  NumberInput,
  DateTimeInput,
  BooleanField,
  ImageField,
  BooleanInput,
  FileInput
} from 'react-admin';
import DateFieldForTimestamp from '../components/DateFieldForTimestamp';
import moment from "moment";

const formatDateTime = (v) => {
  if (typeof v === 'number') {
    const string = moment.unix(v).format('YYYY-MM-DDTHH:mm');
    return string;
  } else if (typeof v === 'string') {
    return v
  }
}

const parseDateTime = (v) => {
  const number = moment(v, 'YYYY-MM-DDTHH:mm').unix();
  return number
}

const searchFilters = [
  <TextInput label="Search Tier Id" source="tierId" alwaysOn />,
  <TextInput label="Search User Id" source="userId" alwaysOn />,
];

const isActiveOptions = [
  { value: 0, text: 'No' },
  { value: 1, text: 'Yes' }
]

export const TierList = (props) => {
  return (
    <List {...props} filters={searchFilters}>
      <Datagrid rowClick="show" className='follower-count-creator'>
        <TextField source="tierId" label="Tier Id" />
        <TextField source="userId" label="User Id" />
        <TextField source="title" />
        <NumberField source="price" className='follower-count-creator'/>
        <TextField source="category" />
        <NumberField source="subscriberCount" className='follower-count-creator'/>
        <SelectField source="isactive" choices={isActiveOptions} optionText="text" optionValue="value" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const TierShow = (props) => {
  return (
    <Show {...props} title="Tier view" >
      <SimpleShowLayout>
        <TextField source="tierId" label="Tier Id" />
        <TextField source="userId" label="User Id" />
        <TextField source="title" />
        <TextField source="description" />
        <NumberField source="price" />
        <TextField source="category" />
        <BooleanField source="enableChat" />
        <NumberField source="capacity" />
        <NumberField source="subscriberCount" />
        <SelectField source="isactive" choices={isActiveOptions} optionText="text" optionValue="value" />
        <NumberField source="postCount" />
        <TextField source="paypalPlanId" />
        <ImageField source="image" />
        <Labeled label="Publish Date">
          <DateFieldForTimestamp source="publishDate" />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  )
}
function PreviewImage({ record, source }) {
 
  if (typeof record === "string") {
    record = {
      [source]: record
    };
  }

  
  return (
    <ImageField source={source} record={record} />)
}
export const TierEdit = (props) => (
  <Edit {...props} title="Tier Edit" >
    <SimpleForm>
      <TextInput fullWidth disabled source="tierId" label="Tier Id" />
      <TextInput fullWidth disabled source="userId" label="User Id" />
      <TextInput fullWidth source="title" />
      <TextInput fullWidth source="description" />
      <NumberInput fullWidth source="price" />
      <TextInput fullWidth source="category" />
      <BooleanInput fullWidth source="enableChat" />
      <NumberInput fullWidth source="capacity" />
      <NumberInput fullWidth source="subscriberCount" />
      <SelectInput fullWidth source="isactive" choices={isActiveOptions} optionText="text" optionValue="value" />
      <NumberInput fullWidth source="postCount" />
      <TextInput fullWidth source="paypalPlanId" />
      <FileInput
      source="image" label="image" 
      multiple={true}
      accept={`image/*`}
        placeholder={<p>Select Your Image</p>} >
      <PreviewImage source="src" />
      </FileInput>
      {/* <TextInput fullWidth source="image" /> */}
      <DateTimeInput
        fullWidth
        source="publishDate"
        format={formatDateTime}
        parse={parseDateTime}
      />
    </SimpleForm>
  </Edit>
);