import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  EditButton,
  DateField,
  Labeled,
  SelectInput,
  SelectField,
  NumberField,
  NumberInput,
  DateTimeInput
} from 'react-admin';
import DateFieldForTimestamp from '../components/DateFieldForTimestamp';
import moment from "moment";

const formatDateTime = (v) => {
  if (typeof v === 'number') {
    const string = moment.unix(v).format('YYYY-MM-DDTHH:mm');
    return string;
  } else if (typeof v === 'string') {
    return v
  }
}

const parseDateTime = (v) => {
  const number = moment(v, 'YYYY-MM-DDTHH:mm').unix();
  return number
}

const searchFilters = [
  <TextInput label="Search Idol Id" source="idolId" alwaysOn />,
  <TextInput label="Search Fan Id" source="fanId" alwaysOn />,
  <TextInput label="Search Tier Id" source="tierId" alwaysOn />,
];

const isStopOptions = [
  { value: 0, text: 'No' },
  { value: 1, text: 'Yes' }
]

export const SubscriptionList = (props) => {
  return (
    <List {...props} filters={searchFilters}>
      <Datagrid rowClick="show">
        <TextField source="subscribeId" label="Subscription Id" />
        <TextField source="idolId" label="Idol Id" />
        <TextField source="fanId" label="Fan Id" />
        <TextField source="tierId" label="Tier Id" />
        <NumberField source="price" />
        <SelectField source="isStop" choices={isStopOptions} optionValue="value" optionText="text" />
        <TextField source="paypalSubscriptionId" />
        <DateFieldForTimestamp source="expiryDate" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const SubscriptionShow = (props) => {
  return (
    <Show {...props} title="Subscription view" >
      <SimpleShowLayout>
        <TextField source="subscribeId" label="Subscription Id" />
        <TextField source="idolId" label="Idol Id" />
        <TextField source="fanId" label="Fan Id" />
        <TextField source="tierId" label="Tier Id" />
        <NumberField source="price" />
        <SelectField source="isStop" choices={isStopOptions} optionValue="value" optionText="text" />
        <TextField source="paypalSubscriptionId" label="Paypal Subscription Id" />
        <Labeled label="Expiry Date">
          <DateFieldForTimestamp source="expiryDate" />
        </Labeled>
        <TextField source="records" />
        <DateField source="yearMonthDay" label="Subscription Date" />
      </SimpleShowLayout>
    </Show>
  )
}

export const SubscriptionEdit = (props) => (
  <Edit {...props} title="Post Report Edit" >
    <SimpleForm>
      <TextInput fullWidth disabled source="subscribeId" label="Subscription Id" />
      <TextInput fullWidth disabled source="idolId" label="Idol Id" />
      <TextInput fullWidth disabled source="fanId" label="Fan Id" />
      <TextInput fullWidth disabled source="tierId" label="Tier Id" />
      <NumberInput fullWidth source="price" />
      <SelectInput fullWidth source="isStop" choices={isStopOptions} optionValue="value" optionText="text" />
      <TextInput fullWidth disabled source="paypalSubscriptionId" label="Paypal Subscription Id" />
      <DateTimeInput
        fullWidth
        source="expiryDate"
        format={formatDateTime}
        parse={parseDateTime}
      />
      <TextField fullWidth source="records" />
      <DateInput source="yearMonthDay" label="Subscription Date" />
    </SimpleForm>
  </Edit>
);