let alphabetstring=/^[A-Za-z]+$/
let fullname=/^[a-zA-Z]{3,}( {1,2}[a-zA-Z]{3,}){0,}$/
let referalcodeexpr=/^\b\w{6,6}\b$/
let phonenumbexpr=/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
let emailexpr=/^([\w\d._\-#])+@([\w\d._\-#]+[.][\w\d._\-#]+)+$/
export const required = (message = 'Required') =>
    value => value ? undefined : message;
export const requiredWithMessage=(message) => value => value ? undefined : message;;
export const maxLength = (max, message = 'Too short') =>
    value => value && value.length > max ? message : undefined;
export const minValue = (min, message = 'Too small') =>
    value => value && value < min ? message : undefined;
export const numbervalidate = (message = 'Must be a number') =>
    value => value && isNaN(Number(value)) ? message : undefined;
export const name = (message = 'Must be a alphabet') =>
    value => value && (!(alphabetstring.exec(value))) ? message : undefined;
export const fullnamevalidate = (message = 'Must be a alphabet') =>
value => value && (!(fullname.exec(value))) ? message : undefined;  
export const contactNumber = (message = 'Please enter valid contact number') =>
    value => value && (!(phonenumbexpr.exec(value))) ? message : undefined;

export const emailrequire = (message = 'Please enter valid email') =>
    value => value && (!(emailexpr.exec(value))) ? message : undefined;    

export const referalcoderequire = (message = 'Please enter valid referal code') =>
    value => value && (!(referalcodeexpr.exec(value))) ? message : undefined; 

export  const validateString = [required(),name() ,maxLength(15)];
export const fullnameString=[requiredWithMessage("Enter Your Fullname"),fullnamevalidate()]
export const  validatePhoneNumber=[required(),contactNumber() ,maxLength(15)]
export const  validateEmail=[requiredWithMessage("Enter Your Email"),emailrequire() ,maxLength(30)]
export const validateReferalcode=[requiredWithMessage("Enter Your Referral Code"),referalcoderequire()]
export const genderValidate=[requiredWithMessage("Select Your Gender")]
export const languageValidate=[requiredWithMessage("Select Your Language")]
export const receivePaymentValidate=[requiredWithMessage("Select Your receive payment.")]
export const dobValidate=[requiredWithMessage("Select Your DOB.")]